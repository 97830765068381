@import "./variables";

input[type="checkbox"] {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  color: black;
  border: 1px solid grey;
  border-radius: 2px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    box-shadow: 0 0 0 2px rgba(39, 94, 254, 0.3);
  }
  //   transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
  &::before {
    position: absolute;
    content: "";
    display: block;
    top: 0;
    left: 5px;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
    animation: wave 0.4s ease;
  }
  &:checked {
    color: #fff;
    border-color: $themeColorDark2;
    background: $themeColor;
    &::before {
      opacity: 1;
    }
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
}
