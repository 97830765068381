@import "./variables";

.modal {
  // left: 65px !important;

  .modal.show .modal-dialog {
    transform: none;
    display: flex;
    justify-content: center;
    margin: auto;
  }

  .modal-dialog {
    margin: none;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    border: none;
    border-radius: 0.3rem;
    outline: 0;
    justify-content: center;
    justify-items: center;
    border-radius: 0px;

  }

  .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.2rem;
    border-bottom: 1px solid #dee2e6;
    background: linear-gradient(to right, $themeColor, $themeColorDark, $themeColorDark2);
    color: whitesmoke;
    border-radius: 0px;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 2px;

    .btn-close {
      background-color: white !important;
    }

  }

  .modal-body {
      padding: 2em;
    // form {
    // }

    .form__input {
      width: 100%;
      border: 0px solid transparent;
      border-radius: 0;
      border-bottom: 1px solid #aaa;
      padding: 1em 0.5em 0.5em;
      padding-left: 2em;
      outline: none;
      margin: 1.5em auto;
      transition: all 0.5s ease;
    }

    .form__input:focus {
      border-bottom-color: black;
      box-shadow: 0 0 0 0.15rem rgba(0, 80, 80, 0.2);
      border-radius: 4px;
    }

    .form-control {
      border-radius: 0;
      &:focus{
      box-shadow: 0 0 0 0.15rem rgba(0, 80, 80, 0.2);
      }
    }

    .input-group-text {
      border-radius: 0;
      background: rgba(0, 80, 80, 0.2);
      text-transform: uppercase;
      color: $themeColorDark2;
      font-size: 80%;
      letter-spacing: 2px;
      height: 38px;
    }

    .select__control{
      border-radius: 0%;
    }
  }
}