@import "./variables";

.departmentHeader {
  background-color: #fff;
  border-bottom: 1px solid lighten($pageTitleText, 20%);
  color: $pageTitleText;
  font-weight: 500;
  font-size: 18px;
  margin: 0 0 0 0;
  padding: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.responsive-table {
  li {
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .table-header {
    background-color: #5d93cf;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: whitesmoke;
  }
  .table-row {
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  }
  .col-1 {
    flex-basis: 16.67%;
  }
  .col-2 {
    flex-basis: 16.67%;
  }
  .col-3 {
    flex-basis: 16.67%;
  }
  .col-4 {
    flex-basis: 16.67%;
  }
  .col-5 {
    flex-basis: 16.67%;
  }
  .col-6 {
    flex-basis: 16.67%;
  }

  @media all and (max-width: 767px) {
    .table-header {
      display: none;
    }
    .table-row {
    }
    li {
      display: block;
    }
    .col {
      flex-basis: 100%;
    }
    .col {
      display: flex;
      padding: 10px 0;
      &:before {
        color: #6c7a89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: right;
      }
    }
  }
}


