@import "./variables";
#msform {
  text-align: center;
  position: relative;
  // margin-top: 30px;
  margin-bottom: 30px;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0px;
  // box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 20px 30px;
  box-sizing: border-box;
  // width: 80%;
  // margin: 0 10%;

  /*stacking fieldsets above each other*/
  position: relative;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
  display: none;
}

/*inputs*/
#msform input,
#msform select,
#msform textarea {
  padding: 25px 15px 12px 15px;
  border: 1px solid $borderColor;
  border-radius: 0px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  // font-family: montserrat;
  color: $themeColorDark;
  font-size: 15px;
}

#msform input[type="checkbox"] {
  width: 0%;
  padding: 10px;
  margin-bottom: 0;
}

label {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  padding-left: 5px;
}
.checkboxLabel {
  margin-left: 5px;
  color: $themeColorDark;
  position: relative;
  top: -6px;
}
label.floating {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  padding-left: 10px;
  padding-top: 5px;
  font-size: 12px;
  color: $themeColor;

  .notRequired {
    display: none;
  }
}

.required {
  color: red;
}

label.floatingNumberDiv {
  left: 55px;
}

.formSelect {
  padding: 25px 15px 12px 15px;
  border: 1px solid $borderColor;
  border-radius: 0px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  // font-family: montserrat;
  color: $themeColorDark;
  font-size: 15px;
  outline: none !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:focus {
    outline: none !important;
  }
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid rgb(93, 147, 207);
  outline-width: 0;
  transition: All 0.5s ease-in;
  -webkit-transition: All 0.5s ease-in;
  -moz-transition: All 0.5s ease-in;
  -o-transition: All 0.5s ease-in;
}

/*buttons*/
#msform .action-button {
  max-width: 100px;
  background: #4b8eab;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 5px 10px;
  margin: 10px 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
}

#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px rgb(93, 147, 207);
}

#msform .action-button-previous {
  max-width: 100px;
  background: #4b8eab;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  // padding: 10px 5px;
  padding: 5px 10px;
  margin: 10px 5px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #c5c5f1;
}

/*headings*/
.fs-title {
  font-size: 25px;
  text-transform: uppercase;
  color: $themeColor;
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-weight: bold;
}

.fs-subtitle {
  font-weight: normal;
  font-size: 16px;
  color: $themeColorDark;
  margin-bottom: 20px;
}

/*progressbar*/
#progressbar {
  margin-bottom: 10px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
  display: flex;
  justify-content: space-between;
}

#progressbar li {
  list-style-type: none;
  color: $themeColor;
  text-transform: uppercase;
  font-size: 9px;
  // width: 15.67%;
  // float: left;
  position: relative;
  letter-spacing: 1px;
}
// #progressbarCompany li {
//   list-style-type: none;
//   color: #3a6bb3;
//   text-transform: uppercase;
//   font-size: 9px;
//   width: 33%;
//   float: left;
//   position: relative;
//   letter-spacing: 1px;
// }

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  // width: 24px;
  // height: 24px;
  width: 44px;
  height: 44px;
  line-height: 26px;
  display: block;
  font-size: 12px;
  color: #333;
  background: rgb(230, 230, 230);
  border-radius: 25px;
  margin: 0 auto 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

/*progressbar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: $themeColor;
  color: white;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: $themeColor;
  color: #fff;
}

.profile-img {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  img {
    width: 100%;
    max-width: 200px;
    height: 200px;
    object-fit: contain;
    // height: 87%;
    display: block;
  }
  .file {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    border: none;
    border-radius: 0;
    font-size: 15px;
    background: #212529b8;
    max-width: 200px;
    &:hover {
      background-color: rgb(93, 147, 207);
    }

    input {
      position: absolute;
      opacity: 0;
      right: 0;
      top: 0;
      max-width: 200px;
    }
  }
}

.profile-img1 {
  // text-align: center;
  // display: flex;
  // align-items: center;
  // flex-direction: column;
  // margin: 20px;
  position: relative;
  img {
    width: 100%;
    max-width: 200px;
    // height: 87%;
    // display: block;
    height: 200px;
    object-fit: contain;
  }
  .file {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    border: none;
    border-radius: 0;
    font-size: 15px;
    background: $imageBtnColor;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    color: $whiteColor;
    &:hover {
      background-color: $themeColor;
      color: $whiteColor;
    }

    input {
      position: absolute;
      opacity: 0;
      right: 0;
      top: 0;
      max-width: 200px;
    }
  }
  .deleteImg {
    color: red;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    font-size: 23px;
  }
}

.deleteImg {
  color: red;
  cursor: pointer;
  position: absolute;
  left: 58%;
  top: 17%;
  z-index: 3;
  font-size: 23px;
}

// #progressbar {
//   margin-bottom: 30px;
//   overflow: hidden;
//   /*CSS counters to number the steps*/
//   counter-reset: step;
// }
// #progressbar li {
//   list-style-type: none;
//   color: white;
//   text-transform: uppercase;
//   font-size: 9px;
//   width: 15.67%;
//   float: left;
//   position: relative;
// }
// #progressbar li:before {
//   content: counter(step);
//   counter-increment: step;
//   width: 20px;
//   line-height: 20px;
//   display: block;
//   font-size: 10px;
//   color: #333;
//   background: white;
//   border-radius: 3px;
//   margin: 0 auto 5px auto;
// }
// /*progressbar connectors*/
// #progressbar li:after {
//   content: "";
//   width: 100%;
//   height: 2px;
//   background: white;
//   position: absolute;
//   left: -50%;
//   top: 9px;
//   z-index: -1; /*put it behind the numbers*/
// }
// #progressbar li:first-child:after {
//   /*connector not needed before the first step*/
//   content: none;
// }
// /*marking active/completed steps green*/
// /*The number of the step and the connector before it = green*/
// #progressbar li.active:before,
// #progressbar li.active:after {
//   background: #27ae60;
//   color: white;
// }

.time_picker {
  width: 150px;
  .react-time-picker__clear-button:focus,
  .react-time-picker__inputGroup__input:focus {
    outline: none;
  }

  .react-time-picker__inputGroup__input,
  .react-time-picker__inputGroup__hour {
    border: none;
  }

  .react-time-picker__inputGroup__input:invalid {
    background: #e80d3f;
  }

  .react-time-picker__wrapper {
    // border: thin solid $themeColor !important;
    // border-radius: 5px;
    padding: 5px;
    // color: $themeColor;
  }
  .react-time-picker__inputGroup__input--hasLeadingZero {
    margin-left: 0em;
    // padding-left: calc(1px + 0.54em);
  }
}

#msform .letsee input {
  padding: 0;
  border: 0px solid #ccc;
  border-radius: 0px;
  margin-bottom: 0px;
  width: auto;
  box-sizing: border-box;
  color: #2c3e50;
  font-size: 13px;

  min-width: 0.54em !important;
  height: 100% !important;
  padding: 0 1px !important;
  border: 0 !important;
  background: none !important;
  font: inherit !important;
  box-sizing: content-box !important;
}
// .checkboxes {
//   input[type="checkbox"] {
//     padding: "";
//     border: 1px solid #ccc;
//     border-radius: 0px;
//     margin-bottom: 10px;
//     // width: 100%;
//     box-sizing: border-box;
//     font-family: montserrat;
//     color: #2c3e50;
//     font-size: 13px;
//   }
// }

.table-custom-phone {
  margin-bottom: 0 !important;
  tbody td {
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: none;
  }
}
