@import "./variables";

.contactDetailBoxHolder {
  align-items: center;
  height: 100%;
  margin-bottom: 10px;


  .detailBoxTitle {
    background-color: $themeColor;
    padding: 5px 8px;
    color: $whiteColor;
    display: inline-block;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 10px;
  }

  .detailImageHolder {
    //   box-shadow: 3px 3px 3px $shadowColor;
    padding: 3px;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    
    img {
      max-width: 100%;
      max-width: 55px;
      max-height: 55px;
      min-width: 55px;
      min-height: 55px;
      height: 55px;
      width: 55px;
      height: 55px;
      width: 55px;
      object-fit: contain;
      border: 3px solid #979797;
      border-radius: 100rem;
      padding: 5px;
      object-fit: contain;
    }
  }


  .contactDetailTable {
    margin-top: 10px;
    margin-left: 10px;
    .name {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 3px;
    }

    .info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        margin-right: 20px;
        text-align: center;

        i {
          height: 20px;
          width: 20px;
          font-size: 13px;
          background: $themeColor;
          color: #fff;
          // padding: 4px;
          padding-top: 3.5px;
          border-radius: 100rem ;
          margin-right: 5px;
        }

        span {
          font-size: 15px;
        }
      }
    }
  }


  .detailBoxContentHolder {
    padding: 5px;

    .detailImageHolder {
      max-width: 100px;
      max-height: 100px;
      //   box-shadow: 3px 3px 3px $shadowColor;
      padding: 3px;
      display: flex;
      justify-content: center;

      img {
        max-width: 100%;
        height: 55px;
        width: 55px;
        object-fit: contain;
        border: 1px solid #000;
        object-fit: contain;
      }
    }

    .contactDetailTable {
      border: none !important;
      border-collapse: separate !important;
      font-size: 13px;

      td {
        border-top: none !important;
        border-bottom: none !important;
        word-break: break-all;
      }
    }

    .showAllBtn {
      cursor: pointer;
      font-size: 11px;
      float: right;

      &:hover,
      &:focus,
      &:visited {
        font-weight: bold;
        font-size: 11px;
      }
    }
  }
}

.collapsibleCardBodyHeader {
  width: 100%;
  background: $themeColorDark !important;
  padding: 5px;
  color: $whiteColor;
  font-weight: 600;
  font-size: 13px;
}

.companyLogoDetail {
  justify-content: center;
  display: flex;
  align-items: center;

  .companyLogoContainer {
    max-width: 130px;
    max-height: 130px;
    box-shadow: 1px 2px 3px $shadowColor;
    padding: 3px;

    img {
      max-width: 100%;
      max-height: 124px;
      object-fit: contain;
    }
  }

  .companyLogoContainer1 {
    max-width: 200px;
    max-height: 200px;
    box-shadow: 1px 2px 3px $shadowColor;
    padding: 3px;

    img {
      max-width: 100%;
      max-height: 194px;
      object-fit: contain;
    }
  }
}

.textColor {
  color: $themeColor;
}

.userDetailHolder {
  // border: 1px solid $borderColor;
  // padding: 10px;
  color: $themeColor;
}