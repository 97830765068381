.editor {
  .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    min-height: 170px;
    max-height: 450px;
  }
  .ck-focused {
    min-height: 170px;
    max-height: 450px;
  }
}
.submit {
  float: right;
  margin-top: 12px;
}

.form-control-new {
  padding: 25px 15px 12px 15px;
  border: 1px solid #5d93cf50;
  border-radius: 0px;
  width: 100%;
  box-sizing: border-box;
  color: #203a43;
  font-size: 15px;
}
