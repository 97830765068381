@import "./variables";

.custom-btn {
  // width: 130px;
  // height: 40px;
  padding: 10px 25px;
  // border: 2px solid #000;
  // font-family: "Lato", sans-serif;
  font-weight: 400;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  letter-spacing: 1px;
  text-transform: uppercase;
}

// .custom-btn {
//   width: 100px;
//   background: rgb(93, 147, 207);
//   font-weight: bold;
//   color: white;
//   border: 0 none;
//   border-radius: 25px;
//   cursor: pointer;
//   padding: 10px 5px;
//   margin: 10px 5px;
// }

.btn-9 {
  // z-index: 2;
  // transition: all 0.3s ease;
  // overflow: hidden;
  // width: 100px;
  background: lighten($themeColor, 10%);
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 5px 20px;
  font-size: 12px;
  // margin: 10px 5px;
}

.edit {
  background: $editBtnColor;
}

.delete {
  background: $deleteBtnColor;
}

// .submit {
//   background: $submitBtnColor;
// }
.all {
  background: lighten($themeColor, 20%);
}

// .btn-9:after {
//   position: absolute;
//   content: " ";
//   z-index: -1;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   transition: all 0.3s ease;
// }
// .btn-9:hover {
//   box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
//     -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
//     inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5),
//     inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
//   color: #fff;
// }
.btn-9:hover {
  box-shadow: 0 0 0 2px white, 0 0 0 3px $themeColor;
}

.btn-9:hover:after {
  // box-shadow: 0 0 0 2px white, 0 0 0 3px rgb(93, 147, 207);
  // -webkit-transform: scale(2) rotate(180deg);
  // transform: scale(2) rotate(180deg);
  // // background: #000;
  // box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
  //   -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
  //   inset -4px -4px 6px 0 rgba(255, 255, 255, 0.5),
  //   inset 4px 4px 6px 0 rgba(116, 125, 136, 0.3);
}

.flag-edit:hover {
  box-shadow: 0 0 0 2px white, 0 0 0 3px $editBtnColor;
  background: $editBtnColor;
}

.flag-delete:hover {
  box-shadow: 0 0 0 2px white, 0 0 0 3px $deleteBtnColor;
}

.flag-normal:hover {
  background: $themeColor;
}

// .flag-all:hover {
//   background: rgb(181, 224, 81);
// }

.disabledBtn {
  opacity: 0.65;
  cursor: not-allowed !important;
}
.disabledBtn:hover {
  box-shadow: none;
}
.enableBtn {
  opacity: none;
  cursor: pointer;
}
.enableBtn:hover {
  box-shadow: 0 0 0 2px white, 0 0 0 3px $themeColor;
}
