//define general colours and scss constants here

$blackColor: #000000;
$loginBg: #f4f7fb;
$loginTemplateColor: #3521b5;
$loginShadowColor: rgba(0, 0, 0, 0.13);
$shadowColor: rgba(0, 0, 0, 0.33);
$whiteColor: #fff;
$themeColor: #2c5364;
$themeBg: #5d93cf50;
$editBtnColor: #22b352;
$deleteBtnColor: #b4160b;
$submitBtnColor: #076e8d;
$themeColorDark: #203a43;
$themeColorDark2: #203a43;
$imageBtnColor: #212529b8;
$borderColor: #5d93cf50;

$pageTitleText: #7c7c7c;
$tableHeader: #777;
